import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// redux
import { getInitialize } from 'redux/slices/auth';
import { getTahunPelajaran, getsemesterActive } from 'redux/slices/setting';


export default function SettingProvider({ children }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInitialize());
    dispatch(getTahunPelajaran())
    dispatch(getsemesterActive())
  }, [dispatch]);

  return <>{children}</>;
}
