import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import uiReducer from './slices/ui';
import authReducer from './slices/auth';
import settingReducer from './slices/setting';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['settings']
};

const authPersistConfig = {
  key: 'auth',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['isAuthenticated']
};

const rootReducer = combineReducers({
  ui: uiReducer,
  setting: settingReducer,
  auth: persistReducer(authPersistConfig, authReducer)
});

export { rootPersistConfig, rootReducer };
