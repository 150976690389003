import { createSlice } from '@reduxjs/toolkit';
// utils 
import { axiosInstance as axios, endpoint } from 'utils/axios';
import { setActiveMenu, setMenuActions } from './ui';
// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  user: null,
  roles:[],
  menus:[],
  actions:[],
  detail:null,
  students:[]
}; 

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // INITIALISE
    getInitialize(state, action) {
      state.isLoading = false;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
      state.roles = action.payload.roles;
      state.menus = action.payload.menus;
      state.actions = action.payload.actions;
      state.detail = action.payload.detail;
      state.students = action.payload.students;
    },

    // LOGIN
    loginSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.roles = action.payload.roles;
      state.menus = action.payload.menus;
      state.actions = action.payload.actions;
      state.detail = action.payload.detail;
      state.students = action.payload.students;
    },

    // REGISTER
    registerSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.roles = action.payload.roles;
      state.menus = action.payload.menus;
      state.actions = action.payload.actions;
      state.detail = action.payload.detail;
    },

    // LOGOUT
    logoutSuccess(state) {
      state.isAuthenticated = false;
      state.user = null;
      state.roles = [];
      state.menus = [];
      state.actions = [];
      state.detail = null;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

// ----------------------------------------------------------------------

export function login({ username, password }) {
  return async (dispatch) => {
    const response = await axios.post(endpoint.login, {
      username,
      password,
      device_type_value:1
    });
    if(response && response.data){
      const { access_token, user, roles, menus, actions, detail, students } = response.data;
      if (user && access_token) {
        setSession(access_token);
        dispatch(slice.actions.loginSuccess({ 
          user:user, 
          roles:roles,
          menus:menus, 
          actions:actions,
          detail: detail,
          students: students 
        }));      

        if (menus && actions) {
          const route = window.location.pathname;
          const filteredMenus = menus.filter(menu => menu.webPath === route)
          const menu = filteredMenus[0]
          if (menu) {
            dispatch(setActiveMenu(menu))
            dispatch(setMenuActions(actions.filter(action => action.menu_id === menu.id)))
          }
        }

        return response;
      }
    }
    
  };
}

// ----------------------------------------------------------------------

export function register({ username, password, firstName, lastName }) {
  return async (dispatch) => {
    const response = await axios.post(endpoint.register, {
      username,
      password,
      firstName,
      lastName
    });
    
    if(response){
      const { token, user } = response.data;

      window.localStorage.setItem('accessToken', token);
      dispatch(slice.actions.registerSuccess({ user }));
    }
    
  };
}

// ----------------------------------------------------------------------

export function logout() {
  return async (dispatch) => {
    setSession(null);
    dispatch(slice.actions.logoutSuccess());
  };
}

// ----------------------------------------------------------------------

export function getInitialize() {

  return async (dispatch) => {
    
    try {
      const accessToken = window.localStorage.getItem('accessToken');

      if (accessToken) {
        setSession(accessToken);
        let params = {
          device_type_value:1
        }
        const response = await axios.get(endpoint.user.by_token,{params:params});
        if (response && response.data) {
          dispatch(
            slice.actions.getInitialize({
              isAuthenticated: true,
              user: response.data.user,
              roles: response.data.roles,
              menus: response.data.menus,
              actions: response.data.actions,
              detail: response.data.detail,
              students: response.data.students
            })
          );
          
          if (response.data.menus && response.data.actions) {
            const route = window.location.pathname;
            const filteredMenus = response.data.menus.filter(menu => menu.webPath === route)
            const menu = filteredMenus[0]
            if (menu) {
              dispatch(setActiveMenu(menu))
              dispatch(setMenuActions(response.data.actions.filter(action => action.menu_id === menu.id)))
            }
          }

        }else{
          dispatch(logout())
        }
      } else {
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: false,
            user: null,
            roles:[],
            menus:[],
            actions:[],
            detail:null,
            students:[]
          })
        );
      }
    } catch (error) {
      console.error(error);
      dispatch(
        slice.actions.getInitialize({
          isAuthenticated: false,
          user: null,
          roles:[],
          menus:[],
          actions:[],
          detail:null,
          students:[]
        })
      );
    }
  };
}
