import React, { lazy, Suspense, useEffect } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { store, persistor } from './redux/store';
import SettingProvider from 'utils/SettingProvider';
import LoadingScreen from 'components/Loading';
//import Home from 'views/home/Home';
//import Login from 'views/login/Login';
//import Register from 'views/register/Register';
//import Dashboard from 'views/dashboard/Dashboard';
//import Reset from 'views/login/Reset';
//import Activation from 'views/activation/Activation';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Echo from "laravel-echo"
import Pusher from 'pusher-js';
import { api } from 'utils/axios';

 

export const EchoInstance = new Echo({
  broadcaster: 'pusher',
  key: '1111111111',
  wsHost: api.wshost,
  wsPort: process.env.REACT_APP_BACKEND_MODE === 'PROD' ? 80:6001,
  wssPort: process.env.REACT_APP_BACKEND_MODE === 'PROD' ? 443:6001,
  forceTLS: process.env.REACT_APP_BACKEND_MODE === 'PROD',
  disableStats: true,
  scheme: process.env.REACT_APP_BACKEND_MODE === 'PROD' ? 'https':'http'
});

const Home =  lazy(() => import("views/home/Home"))
const Dashboard =  lazy(() => import("views/dashboard/Dashboard"))
const Login =  lazy(() => import("views/login/Login"))
const Register =  lazy(() => import("views/register/Register"))
const Reset =  lazy(() => import("views/login/Reset"))
const Activation =  lazy(() => import("views/activation/Activation"))

const falbackComponent = () => {
  return null
}

const history = createBrowserHistory();
const theme = createTheme();
export default function App() {
  

  return (

    <ThemeProvider theme={theme}>
      <ReduxProvider store={store}>
        <PersistGate loading={<LoadingScreen />} persistor={persistor}>
          <Router history={history}>
            <SettingProvider>
            <Suspense fallback={falbackComponent}>    
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route path="/home" component={Home} />
                  <Route path="/login" component={Login} />
                  <Route path="/reset" component={Reset} />
                  <Route path="/register" component={Register} />
                  <Route path="/dashboard" component={Dashboard} />
                  <Route path="/activation" component={Activation} />
                </Switch>
                </Suspense>
            </SettingProvider>
          </Router>
        </PersistGate>
      </ReduxProvider>
    </ThemeProvider>

  );
}
