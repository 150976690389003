import { createSlice } from '@reduxjs/toolkit';
// utils 
import { axiosInstance as axios, endpoint } from 'utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  activeMenu: {},
  previousMenu: {},
  menuActions: [],
  activeForms: [],
  isOpenSnackbar: false,
  snackbarMessage: '',
  snackbarVariant: 'success',
  detailId: undefined,
  favoriteMenus: []
};

const slice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    showSuccessSnackbar(state, action) {
      state.isOpenSnackbar = true;
      state.snackbarVariant = 'success';
      state.snackbarMessage = action.payload;
    },

    showErrorSnackbar(state, action) {
      state.isOpenSnackbar = true;
      state.snackbarVariant = 'error';
      state.snackbarMessage = action.payload;
    },

    showInfoSnackbar(state, action) {
      state.isOpenSnackbar = true;
      state.snackbarVariant = 'info';
      state.snackbarMessage = action.payload;
    },

    hideSnackbar(state) {
      state.isOpenSnackbar = false;
    },

    setActiveMenu(state, action) {
      state.activeMenu = action.payload;
    },

    setPreviousMenu(state, action) {
      state.previousMenu = action.payload;
    },

    setMenuActions(state, action) {
      state.menuActions = action.payload;
    },

    setFavoriteMenus(state, action) {
      state.favoriteMenus = action.payload;
    },

    setActiveForms(state, action) {
      state.activeForms = action.payload;
    },

    setDetailId(state, action) {
      state.detailId = action.payload;
    },

  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setFavoriteMenus, startLoading, stopLoading, setActiveMenu, setPreviousMenu, setMenuActions, setActiveForms, showErrorSnackbar, showSuccessSnackbar, showInfoSnackbar, hideSnackbar, setDetailId } = slice.actions;

export const addFavoriteMenu = ({ user_id, menu_id }) => {
  return async (dispatch) => {
    const response = await axios.post(endpoint.menu.favorite, { user_id, menu_id });
    if (response) {
      dispatch(
        slice.actions.setFavoriteMenus(response.data)
      );
    };
  }
}

  export const deleteFavoriteMenu = ({ user_id, menu_id }) => {
    return async (dispatch) => {
      const response = await axios.delete(endpoint.menu.favorite, { data: { user_id, menu_id } });
      if (response) {
        dispatch(
          slice.actions.setFavoriteMenus(response.data)
        );
      };
    }
  }
