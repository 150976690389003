import { createSlice } from '@reduxjs/toolkit';
// utils

import { axiosInstance as axios, endpoint } from 'utils/axios';
// ----------------------------------------------------------------------

const initialState = {
  tahunPelajaran:{},
  semesterActive:{}
};

const slice = createSlice({
  name: 'setting',
  initialState,
  reducers: {    
    settahunPelajaran(state, action) {
      state.tahunPelajaran = action.payload;
    }, 
    setsemesterActive(state, action) {
      state.semesterActive = action.payload;
    }, 
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { tahunPelajaran, semesterActive} = slice.actions;

export function getTahunPelajaran() {
  return async (dispatch) => {
    const response = await axios.get(endpoint.tahunPelajaran.active);
        if (response) {
          dispatch(
            slice.actions.settahunPelajaran(response.data)
          ); 
  };
}
}

export function getsemesterActive() {
  return async (dispatch) => {
    const response = await axios.get(endpoint.tahunPelajaran.semesterActive);
        if (response) {
          dispatch(
            slice.actions.setsemesterActive(response.data)
          ); 
  };
}
}


